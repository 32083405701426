<template>
  <div class="main">
    <div class="banner"></div>
    <div class="cha">
      <div class="content">
        <div class="title">产品概述</div>
        <div class="s">
          5G云台席是基于5G高清音视频连线方式提供远程面对面服务的新型销售和服务
          渠道，实现线上与线下服务的无缝对接。既能提升线下厅店生产效率，也能提
          升线上客户服务感知。
        </div>
        <img
          src="../../assets/img/productList/cloud/could_summary_title.png"
          alt=""
        />
        <div class="flex_box">
          <div class="item" v-for="(i, index) in cl" :key="index">{{ i }}</div>
        </div>
      </div>
    </div>
    <div class="fe">
      <div class="title_b">
        <b>核心功能</b>
        <div class="s">
          屏幕共享、用户服务密码鉴权、电子签名板、人脸识别等高新技术的引入应用
        </div>
      </div>
      <div class="content">
        <Features :list="fl"></Features>
      </div>
    </div>

    <div class="sc">
      <div class="title_b">
        <b>业务场景</b>
        <div class="s">
          业务选择、排队等待、用户认证、视频沟通、电子签名、签字确认全流程业务场景
        </div>
        <div class="icon"></div>
      </div>
      <div class="content">
        <img src="../../assets/img/productList/cloud/scene.png" alt="" />
      </div>
    </div>
    <div class="tl">
      <div class="title_b">
        <b>技术支持</b>
        <div class="icon"></div>
      </div>
      <div class="content">
        <div class="card" v-for="(i, index) in fl1" :key="index">
          <img :src="i.icon" class="icon" alt="" />
          <img :src="i.img" alt="" />
          <div class="b">{{ i.title }}</div>
          <div class="text">{{ i.text }}</div>
        </div>
      </div>
    </div>

    <div class="adv">
      <div class="title_b">
        <b>产品优势</b>
        <div class="icon"></div>
      </div>
      <div class="content">
        <div class="card" v-for="(i, index) in al" :key="index">
          <img :src="i.img" alt="" />
          <div class="text">
            <div class="b">{{ i.title }}</div>
            <div>{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Features from "../../components/features.vue";
export default {
  components: { Features },
  data() {
    return {
      cl: ["5G高清语音视频", "产品化程度高", "实时在线交互", "多渠道快速接入"],
      fl: [
        {
          img: require("../../assets/img/productList/cloud/core_1.png"),
          title: "实现在线受理",
          text: `用户可以通过音视频连接厅店人员，享受"面对面"业务办理服务`,
        },
        {
          img: require("../../assets/img/productList/cloud/core_2.png"),
          title: "端到端业务受理",
          text: "线上排队叫号、服务密码认证、人脸识别、远程屏幕分享、电子签名版等业务受理过程",
        },
        {
          img: require("../../assets/img/productList/cloud/core_3.png"),
          title: "远程屏幕分享",
          text: "用户可以分享屏幕，在客服的指导下，在线上渠道进行业务受理，并支持计件计酬",
        },
        {
          img: require("../../assets/img/productList/cloud/core_4.png"),
          title: "后端运营能力",
          text: "支持 5G 云台席服务后端运营能力，例如排班管理、运营数据分析、音视频服务质量巡检",
        },
        {
          img: require("../../assets/img/productList/cloud/core_5.png"),
          title: "专属客户服务",
          text: `支持多队列排队机制（地市队列、5G 业务专席、政企专席、新业务专席）专属客服服务`,
        },
        {
          img: require("../../assets/img/productList/cloud/core_6.png"),
          title: "线上渠道覆盖全面",
          text: `APP、小程序、H5、公众号等多种形式，全面覆盖线上渠道。`,
        },
      ],
      fl1: [
        {
          img: require("../../assets/img/productList/cloud/could_support_img_1.jpg"),
          icon: require("../../assets/img/productList/cloud/could_support_icon_1.png"),
          title: "接口对接",
          text: "提供标准 API 文档，并可定制化接口，包括：服务密码鉴权、用户单点登录接口、电子签名板接口等。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_support_img_2.jpg"),
          icon: require("../../assets/img/productList/cloud/could_support_icon_2.png"),
          title: "接口对接",
          text: "提供标准 API 文档，并可定制化接口，包括：服务密码鉴权、用户单点登录接口、电子签名板接口等。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_support_img_3.jpg"),
          icon: require("../../assets/img/productList/cloud/could_support_icon_3.png"),
          title: "环境部署",
          text: "包括：部署架构；硬件要求；组件要求；网络要求等另行说明。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_support_img_4.jpg"),
          icon: require("../../assets/img/productList/cloud/could_support_icon_4.png"),
          title: "部署与联调计划",
          text: "硬件或网络环境具备情况下，部署15天内完成；接口联调取决于联调的双方，正常情况下一天一接口。",
        },
      ],
      al: [
        {
          img: require("../../assets/img/productList/cloud/could_advantage_title_1.png"),
          title: "5G高清音视频",
          text: "高保真、3D环绕立体声体验、全频带采样、基于机器学习的3A算法、自研抗丢包编码技术。3D环绕立体声、听觉视觉增强、沉浸式视觉体验,持续性网络探测、动态网络流控、超分辨率。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_advantage_title_2.png"),
          title: "微服务架构设计",
          text: "通过微服务架构对接原有生产系统。线上渠道和CRM前台系统仅需少量能力开放改造即可实现5G云台席产品与核心业务系统的对接。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_advantage_title_3.png"),
          title: "安全可靠",
          text: "所有通信采用加密传输方式，所有视频内容将保存于云端，提供后续质量巡检、审计、稽核等工作作为依据。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_advantage_title_4.png"),
          title: "组件化",
          text: "纵向组件化，横向分层设计，随着业务量的提升能够快速扩展平台能力，确保业务需求的灵活响应和快速支撑。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_advantage_title_5.png"),
          title: "过去到快速接入",
          text: "支持嵌入各种APP、小程序、微信公众号等线上渠道，快速响应特殊时期的营销能力。",
        },
        {
          img: require("../../assets/img/productList/cloud/could_advantage_title_6.png"),
          title: "产品化程度高",
          text: "采用SDK+代码包快速部署，基于DEVOPS自动化运维。",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
$img: "../../assets/img/productList/cloud/";
.banner {
  background-image: url($img + "banner.jpg");
}
.cha {
  height: 820px;
  background: url($img + "s_bg.jpg") center 0 / 2560px 820px no-repeat;
  .content {
    width: 1200px;
    padding: 0 30px;
    .title {
      font-size: 40px;
      color: #000;
      font-weight: bold;
      padding: 130px 0 30px 0;
    }
    .s {
      width: 550px;
      font-size: 16px;
      line-height: 30px;
      color: #818181;
    }
    img {
      width: 310px;
      margin-top: 105px;
    }
    .flex_box {
      margin-top: 65px;
      padding-left: 25px;
      width: 500px;
      height: 90px;
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      font-size: 20px;
      color: #313131;
      .item {
        width: 200px;
      }
      .item::before {
        border-radius: 50%;
        vertical-align: 4px;
        width: 10px;
        height: 10px;
        background-image: linear-gradient(0deg, #2196f3 0%, #1b65fb 100%);
        display: inline-block;
        content: "";
        margin-right: 30px;
      }
    }
  }
}
.fe {
  background: url($img + "core_bg.jpg") center 0/665px 274px no-repeat;
  .content {
    padding: 25px 0 75px 0;
  }
}
.sc {
  background-color: #f6f9fc;
  padding-bottom: 58px;
  .content {
    box-shadow: 0px 10px 30px 0px rgba(88, 130, 172, 0.15);
  }
}
.tl {
  .title_b {
    padding-top: 50px;
    padding-bottom: 55px;
    b {
      color: #0d3a5f;
    }
  }
  background: url($img + "support_bg.jpg") center 0 / 2560px 680px no-repeat;
  padding-bottom: 42px;
  .content {
    display: flex;
    justify-content: space-between;
    .card {
      position: relative;
      width: 255px;
      height: 400px;
      padding: 15px;
      background-color: #ffffff;
      box-shadow: 0px 3px 14px 0px rgba(5, 5, 5, 0.03);
      border-radius: 20px;
      font-size: 16px;
      line-height: 28px;
      color: #818181;
      img {
        border-radius: 10px;
      }
      .icon {
        position: absolute;
        top: 156px;
        left: 21px;
        width: 78px;
        height: 78px;
      }
      .b {
        font-weight: bold;
        margin-top: 50px;
        font-size: 22px;
        color: #313131;
      }
      .text {
        padding: 0 15px;
        margin-top: 25px;
      }
    }
  }
}
.adv {
  padding-bottom: 120px;
  .title_b {
    padding-top: 45px;
    .icon {
      margin-top: 0;
    }
  }
  .content {
    height: 700px;
    background: url($img + "a_img.jpg") center 0 / 360px 700px no-repeat;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      padding-right: 220px;
      width: 410px;
      margin-bottom: 60px;
      .b {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
      }
      font-size: 16px;
      line-height: 24px;
      color: #818181;
      .text {
        padding-right: 15px;
        text-align: right;
      }

      &:nth-child(2n) {
        padding-right: 0;
        padding-left: 220px;
        .text {
          padding-left: 15px;
          text-align: left;
        }
      }
      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
}
</style>