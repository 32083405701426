<template>
  <div class="flex_f">
    <div class="card_f" v-for="(i, index) in list" :key="index">
      <img :src="i.img" alt="" />
      <div class="title">{{ i.title }}</div>
      <div class="color"></div>
      <div>{{ i.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: ["list"],
};
</script>

<style lang="scss" scoped>
.flex_f {
  width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .card_f {
    width: 350px;
    text-align: center;
    font-size: 16px;
    color: #616161;
    margin-bottom: 120px;
    &:last-child,&:nth-last-child(2),&:nth-last-child(3){
        margin-bottom: 0;
    }
    img {
      width: 120px;
    }
    .title {
      font-size: 26px;
      color: #313131;
      font-weight: bold;
      margin-top: 20px;
    }
    .color {
      width: 30px;
      height: 8px;
      background-color: #1b65fb;
      border-radius: 4px;
      margin: 20px auto;
    }
  }
}
</style>